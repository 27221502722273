<template>
    <div class="devices">
        <main v-click-outside="handleOutside">
            <header>
                <span class="close" @click="$emit('close')" />
                <h2>Choose which users will receive this call</h2>
            </header>
            <section>
                <figure class="call-icon">
                    <span class="half-circle" />
                    <span class="half-circle dashed mirror" />
                    <img src="/assets/images/icons/phone.svg" alt="">
                </figure>
                <div class="users-to-show">
                    <h6>Users to Call</h6>
                    <div class="users" v-if="loaded && users.length">
                        <template v-for="(user, i) in users" :key="i">
                            <article :class="{active: selectedUsers.includes(user.userId)}" @click="handleUser(user.userId)">
                                <figure>
                                    <img v-if="user.userInfo.avatar" :src="imgURL + user.userInfo.avatar" :alt="`${user.userInfo.name}-avatar`">
                                    <span v-else>
                                        {{user.userInfo.name.charAt(0)}}
                                    </span>
                                </figure>
                                <span>{{user.userInfo.name}}</span>
                                <span v-if="selectedUsers.includes(user.userId)" class="in" />
                            </article>
                        </template>
                    </div>
                    <div v-else-if="loaded && !users.length">
                        <span>No Users to Show</span>
                    </div>
                    <div v-else class="loading">
                        <span class="loader" />
                    </div>
                </div>
            </section>
            <footer>
                <button class="cancel" @click="$emit('close')">Cancel</button>
                <button @click="callUsers">Call</button>
            </footer>
        </main>
    </div>
</template>

<script>
import nodeInstance from '@/services/NodeServiceinstance';

export default {
    props: {
        room: {
            type: String,
            default: () => ''
        }
    },

    data() {
        return {
            users: [],
            selectedUsers: [],
            imgURL: process.env.VUE_APP_DO_SPACES,
            loaded: false
        }
    },

    created() {
        this.getUsersInRoom()
    },

    methods: {
        async getUsersInRoom() {
            const response = await nodeInstance.get(`chats/roomDetails/${this.room}`)
            this.loaded = true


            if (response.status !== 200) {
                this.$emit('close')
                this.emitter.emit("alert", "Couldn't load de users for this room. Please try again.");
                return
            }

            this.users = response.data.users
        },

        handleOutside() {
            this.$emit('close')
        },

        handleUser(user) {
            if (this.selectedUsers.includes(user)) {
                const index = this.selectedUsers.findIndex(el => el === user)
                this.selectedUsers.splice(index, 1)
                return
            }

            this.selectedUsers.push(user)
        },

        callUsers() {
            this.$emit('callUsers', this.selectedUsers)
        }
    }
}
</script>

<style lang="scss" scoped>
    .devices {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        top: 0;
        left: 0;
        z-index: 200;
        display: flex;
        justify-content: center;
        align-items: center;

        main {
            width: 90%;
            max-width: 500px;
            height: 540px;
            background: white;
            display: flex;
            flex-direction: column;
            border-radius: 4px;

            header {
                width: 100%;
                height: 4rem;
                border-right: solid 1px rgba(0,0,0,0.1);
                display: flex;
                justify-content: center;
                align-items: center;
                padding-inline: 20px;
                background: var(--primary-color);
                color: white;
                position: relative;

                .close {
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    

                    &::before, &::after {
                        content: '';
                        width: 16px;
                        height: 1px;
                        position: absolute;
                        background: white;
                    }

                    &::before {
                        transform: rotate(45deg);
                    }
                    &::after {
                        transform: rotate(-45deg);
                    }
                }

                h2 {
                    font-size: 1rem;
                    font-weight: 500;
                    margin: 0;
                    user-select: none;
                }
                
            }

            section {
                width: 100%;
                padding: 20px;
                display: flex;
                gap: 1rem;
                height: calc(500px - 4rem);
                position: relative;

                .users-to-show {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    width: calc(100% - 40px - 1rem);

                    .loading {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }

                    .users {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        overflow: auto;
                        border-left: solid 1px $grey;
                        article {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            cursor: pointer;
                            user-select: none;
                            position: relative;

                            &:hover {
                                background: $grey;
                            }

                            &.active {
                                background: var(--primary-color);
                                color: white;
                            }

                            figure {
                                width: 30px;
                                height: 30px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }

                                span {
                                    width: 100%;
                                    height: 100%;
                                    background: var(--primary-color);
                                    color: white;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }

                            .in {
                                width: 12px;
                                height: 12px;
                                position: absolute;
                                right: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &::before {
                                    content: '';
                                    width: 4px;
                                    height: 2px;
                                    background: white;
                                    transform: rotate(45deg);
                                    position: absolute;
                                    bottom: 3px;
                                    left: -1px;
                                }
                                &::after {
                                    content: '';
                                    position: absolute;
                                    width: 12px;
                                    height: 2px;
                                    background: white;
                                    transform: rotate(-45deg);
                                }
                            }
                            
                        }
                    }
                }
            }

            footer {
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 0px;

                button {
                    background: var(--primary-color);
                    color: white;
                    height: fit-content;
                    height: 40px;
                    width: 50%;
                    font-size: 1rem;
                    cursor: pointer;

                    &.cancel {
                        background: $red;
                    }
                }
            }
                

            .call-icon {
                width: 40px;
                height: 40px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                overflow: hidden;
                
                .half-circle {
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    // background: black;
                    border: solid 1px green;
                    border-right: none;
                    border-radius: 20px 0 0 20px;

                    &.mirror {
                        transform: rotate(180deg);
                    }

                    &.dashed {
                        border-style: dashed none none dashed;
                    }
                }

                img {
                    width: 50%;
                    height: 50%;
                    object-fit: contain;
                }
            }
        }
        
    }
</style>