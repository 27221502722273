<template>
    <section>
        <h2>Site Details</h2>
        <div class="data">
            <template v-for="(item, i) in fields" :key="i">
                <div class="item">
                    <label>{{ item.label }}</label>
                    <span v-if="item.tag !== 'country'">{{ site[item.tag] || 'N/A' }}</span>
                    <span v-else>{{ site.country?.name || 'N/A' }}</span>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        site: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            fields: [
                {
                    label: "Name",
                    tag: "name"
                },
                {
                    label: "Country",
                    tag: "country"
                },
                {
                    label: "Address",
                    tag: "address"
                }
            ]
        }
    }
}
</script>t

<style lang="scss" scoped>
section {
    padding: 20px;

    h2 {
        font-weight: bold;
        font-size: 1.3rem;
        margin: 0;
    }

    .data {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .item {
            margin-top: 12px;
            display: flex;
            flex-direction: column;

            &:first-of-type {
                margin-top: 0;
            }

            label {
                font-weight: 500;
            }

            span {
                margin-top: 4px;
                font-weight: 600;
            }
        }
    }
}
</style>