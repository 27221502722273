<template>
    <div class="contentTitle">
        <span @click="goBack">
            <h3 class="pointer">
                <img src="/assets/images/left-arrow.svg" /> Inbox
            </h3>
        </span>
    </div>
    <div class="contentMenu"></div>
    <div class="ticket-wrapper inbox-message-wrapper" v-if="!error">
        <div class="ticket-left">
            <div class="ticket-left-top"></div>
            <div class="ticket-listing">
                <template v-for="(tab, index) in tabs" :key="index">
                    <template
                        v-if="tab.toLowerCase() !== 'assignees' || (tab.toLowerCase() === 'assignees' && canCreate.includes(getUserProfile.role.id))">
                        <div class="tab" :class="{ active: isActive === index }" @click="isActive = index">
                            <span>{{ tab }}</span>
                            <span v-if="tab.toLowerCase() === 'messages' && ticketUnreads" class="unreads">{{ ticketUnreads
                            }}</span>
                        </div>
                    </template>
                </template>
            </div>
        </div>
        <div class="ticket-right">
            <div class="ticket-left-top">
                <div class="user-details">
                    <figure>
                        <template v-if="ticketDetails.product?.product_images?.length">
                            <img :src="imgURL + ticketDetails.product.product_images[0].image" alt="" />
                        </template>
                        <template v-else>
                            <span>{{ ticketDetails.created_by?.name.charAt(0) ||
                                ticketDetails.ticket_raised?.name.charAt(0) }}</span>
                        </template>
                    </figure>
                    <div v-if="ticketDetails" class="ticket-info">
                        <span class="ticket-name">{{ ticketDetails.title }} #{{ ticketDetails.id }}</span>
                        <span class="ticket-desc">
                            <span>{{ ticketDetails.created_by ? ticketDetails.created_by.name :
                                ticketDetails.ticket_raised?.name }}</span>
                            <span> | {{ ticketDetails.client?.name ? ticketDetails.client.name : "" }}</span>
                            <span class="date"> • created {{ ticketDetails.ticket_raised?.id !==
                                ticketDetails.created_by?.id ? 'by Organisation' : 'by Client' }} {{
        timeSince(ticketDetails.updated_at) }} </span>
                        </span>
                    </div>
                </div>
                <div class="right-corner">
                    <span class="close-tckt" v-if="canCreate.includes(getUserProfile.role.id)"
                        @click="handleCloseTicket">Close Ticket</span>
                    <Timer v-if="canCreate.includes(getUserProfile.role.id) && Object.keys(ticketDetails).length"
                        :timer="ticketTimer" :ticket="ticketDetails.id" @timerStatus="handleTimerStatus" />
                    <span class="call" v-if="isActive === 0 && userBelongsToChat" @click="handleStartCall">
                        <img src="/assets/images/phone-icon.png" alt="" />
                    </span>
                    <div class="priorities" v-if="canCreate.includes(getUserProfile.role.id)">
                        <div class="current" @click="openPriorities = true">
                            <div class="prio">
                                <template v-if="!Object.keys(selectedPriority).length">
                                    <img :src="priority.icon" alt="" />
                                    <span :class="priority.name">{{ priority.name }}</span>
                                </template>
                                <template v-else>
                                    <img :src="selectedPriority.icon" alt="" />
                                    <span :class="selectedPriority.name">{{
                                        selectedPriority.name
                                    }}</span>
                                </template>
                            </div>
                            <div class="arrow" v-if="canCreate.includes(getUserProfile.role.id)">
                                <img src="/assets/images/left-arrow-grey.svg" />
                            </div>
                        </div>
                        <transition name="fadeHeight" mode="out-in">
                            <div v-if="openPriorities && canCreate.includes(getUserProfile.role.id)" ref="prios"
                                class="toChoose" :class="{ openPriority: !closePrios, closePriority: closePrios, }">
                                <div class="priority-option" v-for="(prio, index) in priorities" :key="index"
                                    @click="handlePrio(prio)">
                                    <img :src="prio.icon" />
                                    <span :class="prio.name">{{ prio.name }}</span>
                                    <img class="arrow" v-if="prio.id === undefined"
                                        src="/assets/images/left-arrow-grey.svg" />
                                </div>
                            </div>
                        </transition>
                    </div>
                    <figure class="options" @click="edit = !edit">
                        <img src="/assets/images/black_dots.svg" alt="options" />
                    </figure>
                </div>
            </div>
            <messages v-if="isActive === 0" :timer-status="timerStatus" :details="ticketDetails"
                :call-specific-users="callSpecificUsers" :init-call="startCall" :edit="edit" @reload="getTicketDetails"
                @activeRoom="handleActiveRoom" @isTicket="handleIsTicket" @setUnreads="setUnreads"
                @emptyUsers="callSpecificUsers = []" />
            <assign v-if="isActive === 1" :details="ticketDetails" :canEdit="canCreate.includes(getUserProfile.role.id)"
                @updateData="getTicketDetails" />
            <site-details v-if="isActive === 2" :site="ticketDetails.site" />
            <product-details v-if="isActive === 3" :details="ticketDetails" />
            <Details v-if="isActive === 4" :details="ticketDetails" />
            <Documents v-else-if="isActive === 5" :details="ticketDetails" />
            <Guides v-else-if="isActive === 6" :details="ticketDetails" />
        </div>
        <UsersToRing v-if="usersToRingPop" :room="activeRoom" @close="usersToRingPop = false"
            @callUsers="handleCallUsers" />
        <CloseForm v-if="showCloseForm" :title="'Close Ticket'" :items="toClose(ticketDetails)" :response="response"
            @data="handleCloseForm" @close="showCloseForm = false" />
    </div>
    <div class="error" v-else>
        <span>This ticket does not exist.</span>
    </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import { getCurrentInstance } from "vue";
import { mapActions, mapGetters } from "vuex";
import Messages from "./Messages.vue";
import Assign from "./Assign.vue";
import Details from "./Details.vue";
import Documents from "./Documents.vue";
import Guides from "./Guides.vue";
import ProductDetails from "./ProductDetails.vue";
import SiteDetails from "./SiteDetails.vue";
import timeSince from '@/mixins/timeSince'
import UsersToRing from "@/components/Ui/Call/UsersToRing.vue";
import CloseForm from '@/components/Create/Index.vue'
import Timer from '@/components/Ui/Ticket/Timer.vue'
import api from "@/mixins/optionsApi";

import {
  GET_USER_PROFILE_GETTER,
  CHANGE_TICKET_PRIORITY_ACTION,
} from "@/store/storeconstants";
export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },

  mixins: [timeSince, api],

  data() {
    return {
      title: "Remote Support History",
      tabs: ["Messages", "Assignees", "Site Details", "Product Details", "Ticket Details", "Documents", "Guides"],
      isActive: 0,
      ticketId: "",
      priorityStatus: "",
      priorityFlag: undefined,
      ticketDetails: [],
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
      priorities: [
        {
          id: undefined,
          icon: "/assets/images/nan_flag.svg",
          name: "chose priority",
        },
        {
          id: 1,
          icon: "/assets/images/low_flag.svg",
          name: "low",
        },
        {
          id: 2,
          icon: "/assets/images/medium_flag.svg",
          name: "medium",
        },
        {
          id: 3,
          icon: "/assets/images/high_flag.svg",
          name: "high",
        },
      ],
      selectedPriority: {},
      priority: {},
      openPriorities: false,
      closePrios: false,
      canCreate: [4, 5, 6, 7, 8],
      userBelongsToChat: false,
      edit: false,
      ticketUnreads: 0,
      user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
      startCall: false,
      error: false,
      usersToRingPop: false,
      activeRoom: '',
      callSpecificUsers: [],
      isTicket: true,
      showCloseForm: false,
      response: [],
      ticketTimer: {
        status: 0,
        time: 0
      },
      timerStatus: undefined
    };
  },

  components: {
    Messages,
    Assign,
    Details,
    ProductDetails,
    Documents,
    Guides,
    UsersToRing,
    CloseForm,
    Timer,
    SiteDetails
  },
  
  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
  },

  watch: {
    isActive(val) {
        if(!val) this.getTicketDetails()
    },

    $route() {
        if (this.timerStatus) {
            this.emitter.emit('timer-running', this.ticketDetails)
        }
        
        this.timerStatus = undefined
        this.ticketId = this.$route.params.ticketid
        this.getTicketDetails()
    }
  },

  methods: {
    ...mapActions("ticket", {
      changeTicketPriority: CHANGE_TICKET_PRIORITY_ACTION,
    }),

    toClose(item) {
        const warranty_end = item.product.warranty_end_date ? new Date(item.product.warranty_end_date) : undefined
        if (warranty_end) warranty_end.setHours(23, 59, 59);
        const today = new Date()
        const diffTime = warranty_end - today;
        const diffDays = Math.ceil(Math.abs(diffTime) / (1000 * 60 * 60 * 24));

        let warranty_message = ['This Product has <strong style="color: #d18d54">no Warranty Period</strong> defined']
        if (warranty_end) {
            warranty_message = diffTime > 0 && diffTime < (1000 * 60 * 60 * 24) ? `This product warranty is <strong style="color: green">under warranty</strong>. <br>Expires <strong style="color: green">today</strong>. <small>(${item.product.warranty_end_date})</small>` : diffTime > 0 ? `This product is <strong style="color: green">under warranty</strong>. <br>Expires within <strong style="color: green">${diffDays} days</strong>. <small>(${item.product.warranty_end_date})</small>` : `This product is <strong style="color: red">out of warranty</strong>. <br> Expired <strong style="color: red">${diffDays} days</strong> ago. <small>(${item.product.warranty_end_date})</small>`
        }
        
        return [
            {
                type: 'ticket-times',
                name: 'ticket_times',
                label: 'Ticket Times',
                data: {
                    id: this.ticketDetails.id,
                    created_date: this.ticketDetails.created_at
                }
            },
            {
                type: 'select',
                name: 'assistance_type',
                label: 'Assistance Type',
                required: true,
                options: this.api('assistanceTypes')
            },
            {
                template: 'multiple-select-related',
                name: 'type',
                label: 'Problem Type',
                required: true,
                options: this.api('parent-types'),
                relate: {
                    template: 'select',
                    name: 'type_child',
                    required: true,
                    label: 'Problem Subtype',
                    options: 'child-types'
                }
            },
            {
                type: "message",
                name: "message",
                label: "Warranty",
                message: warranty_message,
                required: false,
            },
            {
                type: "boolean-message",
                name: "charge",
                label: "Charge?",
                message: '',
                default: diffTime < 0,
                required: true,
            },
            {
                type: "file",
                name: "documents",
                label: "Upload Document",
                required: false,
            },
            {
                type: "textarea",
                name: "notes",
                label: "Notes",
                required: true,
            },
        ];
    },

    async getTicketDetails() {
      let orgDataString = localStorage.getItem("organization");
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        const response = await axiosInstance.get(
          `ticket/${this.ticketId}?org_id=${orgData.id}`
        );

        if (!response.data.data) {
            this.error = true
            return
        }

        this.ticketDetails = response.data.data;
        this.userBelongsToChat = true

        const timer = this.ticketDetails.ticket_timer.filter(el => el.status === 1 && el.user_id === this.getUserProfile.id)

        this.ticketTimer = {
            status: timer.length ? timer[0].status : 0,
            time: timer.length ? timer[0].time : 0
        }

        let icon;
        let name;
        const id = this.ticketDetails?.ticket_priority.priority_details.id || 0;

        switch (id) {
          case 1:
            icon = "/assets/images/low_flag.svg";
            name = "low";
            break;
          case 2:
            icon = "/assets/images/medium_flag.svg";
            name = "medium";
            break;
          case 3:
            icon = "/assets/images/high_flag.svg";
            name = "high";
            break;

          default:
            icon = "/assets/images/nan_flag.svg";
            name = "set priority";
            break;
        }

        this.priority = { id, icon, name };
      }
    },

    async handlePrio(prio) {
      if (this.priority.id !== prio.id && prio.id !== undefined) {
        this.selectedPriority = prio;
        this.openPriorities = false;
        this.closePrios = false;
        await this.changeTicketPriority({
          ticket_id: this.ticketDetails.id,
          status: prio.id,
        }).then(() => {
          this.getTicketDetails();
        });
      } else {
        this.closePrios = true;
        setTimeout(() => {
          (this.openPriorities = false), (this.closePrios = false);
        }, 25);
      }
    },

    handleCloseTicket() {
        this.showCloseForm = true
    },

    handleTimerStatus(value) {
        this.timerStatus = value
    },

    goBack() {
      this.$router.push({path : `/remote-support/tickets`})
    },

    setUnreads(value) {
        this.ticketUnreads = value
    },

    async handleCloseForm(data) {
        let orgData = JSON.parse(localStorage.getItem("organization"));
        const id = this.ticketDetails.id;

        const formData = new FormData();

        if (data.documents && data.documents.length) {
            for (let i = 0; i < data.documents.length; i++) {
                formData.append(`form_documents[${i}]`, data.documents[i].og_doc);
            }
        }

        
        if (this.ticketDetails.assigned_users && this.ticketDetails.assigned_users.length) {
            for (let i = 0; i < this.ticketDetails.assigned_users.length; i++) {
                formData.append(`user_ids[${i}]`, this.ticketDetails.assigned_users[i].id);
            }
        }

        formData.append(`org_id`, orgData.id);
        formData.append(`ticket_id`, id);
        formData.append(`status_id`, 5);
        formData.append(`chargeable`, data.charge);

        if (data.assistance_type) formData.append(`assistance_type`, data.assistance_type.id);

        if(data.type) {
            for (let i = 0; i < data.type.length; i++) {
                formData.append(`typology_id[${i}]`, data.type[i].id);
            }
        }

        if(data.notes) formData.append(`notes`, data.notes);

        const response = await axiosInstance.post(`close-ticket`, formData);

        if(response.status === 200) {
            this.response = [true, true]
            this.showCloseForm = false;
            this.$router.push('/remote-support/tickets')
            return
        }

        this.response = [true, false];
    },


    handleStartCall() {
        if (this.isTicket){
            this.usersToRingPop = true
            return
        }

        this.startCall = true
    },

    handleActiveRoom(room) {
        this.activeRoom = room
    },

    handleCallUsers(users) {
        this.callSpecificUsers = users
        this.usersToRingPop = false
    },

    handleIsTicket(value) {
        this.isTicket = value
    }

  },

  created() {
    this.ticketId = this.$route.params.ticketid;
    this.getTicketDetails();
  },

  beforeUnmount() {
    if(this.timerStatus) {
        this.emitter.emit('timer-running', this.ticketDetails)
    }
  },
};
</script>

<style lang="scss" scoped>
.options {
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 15px;
    padding: 10px 2px;
}

.call {
    cursor: pointer;
}

.pointer {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
        width: 15px;
        height: 15px;
    }
}

.ticket-wrapper.inbox-message-wrapper {
    height: 75vh;

    @media only screen and (max-width: 1200px) {
        height: 65vh;
        display: flex;
        flex-direction: column;
    }

    .ticket-left {

        @media only screen and (max-width: 1200px) {
            max-width: unset;
            border-top-right-radius: 5px;
            border: none;
            flex: 0;
        }

        .ticket-left-top {
            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }

        .ticket-listing {

            @media only screen and (max-width: 1200px) {
                display: flex;
                flex-direction: row;
                overflow: auto;
                border-bottom: solid 1px $grey;
            }

            &::-webkit-scrollbar {
                @media only screen and (max-width: 1200px) {
                    display: none;
                }
            }

            .tab {
                display: flex;
                align-items: center;
                position: relative;

                .unreads {
                    position: absolute;
                    right: 30px;
                    width: 20px;
                    height: 20px;
                    background: var(--primary-color);
                    color: white;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.6rem;
                }

                @media only screen and (max-width: 1200px) {
                    border-bottom: solid 2px;
                    border-bottom-color: transparent;
                    padding: 10px 30px;
                }

                &.active {
                    @media only screen and (max-width: 1200px) {
                        background: white;
                        border-bottom-color: var(--primary-color);
                    }
                }
            }
        }

    }

    .ticket-right {
        @media only screen and (max-width: 1200px) {
            height: 90%;
        }

        .ticket-left-top {
            .right-corner {
                @media only screen and (max-width: 1200px) {
                    display: none;
                }
            }
        }
    }
}

.ticket-wrapper.inbox-message-wrapper .ticket-right {
    height: 100%;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top {
    height: 4rem;
    padding: 0 40px;
}

.ticket-listing {
    display: flex;
    flex-direction: column;
}

.ticket-listing .tab {
    padding: 30px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.1s;
}

.ticket-listing .tab.active {
    font-weight: 600;
    color: var(--primary-color);
    background: #f2f5f8;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top {
    display: flex;
    justify-content: space-between;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details {
    display: flex;
    align-items: center;
    gap: 10px;
    user-select: none;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details figure {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details figure span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    color: white;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details .ticket-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details .ticket-info .ticket-desc {
    font-size: 0.8rem;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details .ticket-info .ticket-desc .date {
    color: #cad6e2;
    font-weight: 500;
}

.ticket-left-top {
    .right-corner {
        display: flex;
        align-items: center;
        gap: 20px;

        .close-tckt {
            cursor: pointer;
            user-select: none;
            font-weight: 400;
            padding: 5px 20px;
            color: #ed0423;
            border-radius: 30px;
            border: solid 1px #ed0423;
            transition: 0.2s;

            &:hover {
                border: solid 1px transparent;
                background: #ed0423;
                color: white;
            }
        }
    }
}

.priorities {
    text-transform: capitalize;
    font-weight: 400;
    position: relative;
    display: flex;
    cursor: pointer;
}

.priorities .current {
    display: flex;
    gap: 5px;
}

.priorities .current .prio {
    display: flex;
    gap: 5px;
}

.priorities .current .arrow {
    width: 10px;
    transform: rotate(270deg);
}

.priorities span {
    color: #cad6e2;
    font-size: 0.8rem;
}

.priorities .medium {
    color: #fb9a4b;
}

.priorities .high {
    color: #ed0423;
}

.priorities .toChoose {
    position: absolute;
    right: 0;
    width: 150px;
    box-shadow: 0px 1px 6px #cad6e280;
    border-radius: 5px;
    overflow: hidden;
    z-index: 3;
    background: white;
}

.priorities .toChoose.openPriority {
    animation: open 0.2s linear forwards;
}

.priorities .toChoose.closePriority {
    animation: close 0.2s linear forwards;
}

.priorities .toChoose .priority-option {
    height: 2rem;
    padding: 0 10px;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #cad6e280;
    user-select: none;
}

.priorities .toChoose .priority-option .arrow {
    width: 10px;
    transform: rotate(90deg);
}

.priorities .toChoose .priority-option:last-of-type {
    border: none;
}

.priorities .toChoose .priority-option:hover {
    background: #cad6e280;
}

.error {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes open {
    from {
        max-height: 0px;
    }

    to {
        max-height: 250px;
    }
}

@keyframes close {
    from {
        max-height: 250px;
    }

    to {
        max-height: 0px;
    }
}
</style>